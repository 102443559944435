<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
                        <h4 class="mb-sm-0">Ganti Akun Bank</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'user.profile' }">
                                        Profile
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">Ganti Akun Bank</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Edit Akun Bank</h4>
                        </div>
                        <div class="card-body">
                            <form class="" @submit.prevent="submit">

                                <div class="mb-3">
                                    <label class="form-label">Nama Bank</label>
                                    <input v-model="bank_account.bank_name" class="form-control" />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label">Nomor Akun Bank</label>
                                    <input v-model="bank_account.bank_account_number" class="form-control" type="number" />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label">Nama Akun Bank</label>
                                    <input v-model="bank_account.bank_account_name" class="form-control" />
                                </div>

                                <div class="float-end">
                                    <router-link :to="{ name: 'user.profile' }" type="button"
                                        class="btn btn-warning waves-effect waves-light me-2"><i
                                            class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                                        Kembali
                                    </router-link>
                                    <button :disabled="load" type="submit"
                                        class="btn btn-primary waves-effect waves-light">
                                        <i class="bx font-size-16 align-middle me-2"
                                            :class="{ 'bx-loader bx-spin': load, 'bx-save': !load }"></i>
                                        Simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import { endpoint } from '../../host';
import errorService from '../../services/error-service';
import httpService from '../../services/http-service';

export default {
    components: {
    },
    created() {
        this.getProfile();
    },

    data() {
        return {
            investor: null,
            load: false,
            bank_account: {
                _method: 'put',
                _type: 'bank',
                bank_name: null,
                bank_account_name: null,
                bank_account_number: null,
            }
        };
    },
    methods: {

        async getProfile() {
            try {
                let res = await httpService.get(endpoint.profile);
                this.investor = res.data.data.investor;
                this.bank_account.bank_name = this.investor.bank_name;
                this.bank_account.bank_account_name = this.investor.bank_account_name;
                this.bank_account.bank_account_number = this.investor.bank_account_number;
            } catch (error) {
                errorService.displayError(error.response);
            }
        },

        async submit() {
            try {
                this.load = true;
                let res = await httpService.post(
                    endpoint.investor + `/bank`,
                    this.bank_account,
                );
                this.load = false;
                if (res.status == 200) {
                    Swal.fire({
                        title: "Berhasil",
                        text: "data berhasil disimpan",
                        icon: "success",
                    });
                }
            } catch (error) {
                this.load = false;
                errorService.displayError(error);
            }
        },
    },
};
</script>

<style>
</style>